import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
import React from "react";
var __jsx = React.createElement;
import { useEffect, useState } from 'react';
import { Pressable, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import { isWeb } from '~/common/Env';
import Localize, { LocalizedPrice } from '~/common/Localize';
import { AnalyticsEvents } from '~/common/analytics/Analytics';
import useAnalytics from '~/common/hooks/analytics/useAnalytics';
import useFocusEffect from '~/common/hooks/useFocusEffect';
import useKeyboardAware from '~/common/hooks/useKeyboardAware';
import { removeCartItems, removeOfflineCartItems, sanitizeCartItems, selectCartHasNonRewardItem, selectCartItems, selectIsInRescheduleOrRebookFlow, selectSelectedServiceReward, startNewCart, updateCartItems } from '~/common/slices/cart.slice';
import { selectClients, you } from '~/common/slices/client.slice';
import { FeatureFlagsEnum, selectFeatureFlag } from '~/common/slices/model.slice';
import MainButton, { MainButtonType } from '~/components/MainButton';
import { getRouteUrl, getStaffSelectionRoute, RouteName } from '~/navigation/Routes';
import { Body3, Container, SubTitle2, Theme, themed, Title1, useAppTheme } from '~/styles/Theme';
import { useServices } from '../../useServices';
import TallyDetails from './TallyDetails';
import { TallyFunctions } from './TallyFunctions';
export default function Tally(_ref) {
  var preferredStaff = _ref.preferredStaff,
    providedViewModel = _ref.viewModel,
    onBookWhenRenderingOnlyRewards = _ref.onBookWhenRenderingOnlyRewards;
  var _useRouter = useRouter(),
    push = _useRouter.push;
  var dispatch = useDispatch();
  var isKeyboardVisible = useKeyboardAware();
  var isInRescheduleOrRebookFlow = useSelector(selectIsInRescheduleOrRebookFlow);
  var theme = useAppTheme();
  var analytics = useAnalytics();
  var _useState = useState(false),
    showDetails = _useState[0],
    setShowDetails = _useState[1];
  var _useState2 = useState(),
    tallyModel = _useState2[0],
    setTallyModel = _useState2[1];
  var cartItems = useSelector(selectCartItems);
  var clients = useSelector(selectClients);
  var selectedServiceReward = useSelector(selectSelectedServiceReward);
  var activeClients = clients.filter(function (client) {
    return client.cartItems.length > 0;
  });
  var numberOfClients = activeClients.length;
  var hideGroupBooking = useSelector(selectFeatureFlag(FeatureFlagsEnum.HideGroupBooking));
  var isOnlyRenderingReward = !useSelector(selectCartHasNonRewardItem);
  var _useServices = useServices({
      skipViewModel: providedViewModel != null
    }),
    serviceGroups = _useServices.serviceGroups,
    staffs = _useServices.staffs,
    services = _useServices.services,
    serviceCategories = _useServices.serviceCategories,
    calculatedViewModel = _useServices.viewModel;
  var viewModel = providedViewModel !== null && providedViewModel !== void 0 ? providedViewModel : calculatedViewModel;
  useEffect(function () {
    if (isInRescheduleOrRebookFlow === true) {
      dispatch(startNewCart());
    }
  }, [isInRescheduleOrRebookFlow]);
  useFocusEffect(function () {
    dispatch(sanitizeCartItems());
  });

  // effect to clean up the offline services
  useEffect(function () {
    if (services) {
      dispatch(removeOfflineCartItems(services));
    }
  }, [services]);
  useEffect(function () {
    if (!services || !staffs || !serviceCategories || !serviceGroups || !viewModel) {
      return undefined;
    }
    var tallyModel = TallyFunctions.calculateTally(viewModel, cartItems);
    // if some cart items are orphaned (no match on service or groups) we remove the preferred staff from them
    if (tallyModel.orphanedItems.length > 0) {
      dispatch(updateCartItems({
        cartItems: tallyModel.orphanedItems,
        preferredStaff: undefined
      }));
    }
    // if some cart items are missing, we remove them as they are no loger offered by the salon
    if (tallyModel.missingItems.length > 0) {
      analytics.logAnalyticsEvent(AnalyticsEvents.TallyRemovedMissingItems, {
        missingItems: tallyModel.missingItems.map(function (cartItem) {
          return cartItem.item.id;
        })
      });
      dispatch(removeCartItems(tallyModel.missingItems));
    }
    setTallyModel(tallyModel);
  }, [services, staffs, serviceCategories, cartItems, clients, selectedServiceReward]);
  useEffect(function () {
    if (tallyModel && tallyModel.entries.length === 0) {
      setShowDetails(false);
    }
  }, [tallyModel]);
  if (!tallyModel) {
    return null;
  }
  function onShowHidePress() {
    analytics.logAnalyticsEvent(showDetails ? AnalyticsEvents.TallyDetailsClosed : AnalyticsEvents.TallyDetailsOpened);
    setShowDetails(!showDetails);
  }
  function onBookNow() {
    if (isOnlyRenderingReward) {
      onBookWhenRenderingOnlyRewards === null || onBookWhenRenderingOnlyRewards === void 0 || onBookWhenRenderingOnlyRewards();
    } else if (!(tallyModel !== null && tallyModel !== void 0 && tallyModel.canBeBooked)) {
      push(getRouteUrl(RouteName.LinkedServices));
    } else if (preferredStaff && !cartItems.some(function (cartItem) {
      return cartItem.preferredStaff == null;
    })) {
      console.log('preferredStaff && !cartItems.some((cartItem) => cartItem.preferredStaff == null): ', preferredStaff && !cartItems.some(function (cartItem) {
        return cartItem.preferredStaff == null;
      }));
      push(getRouteUrl(RouteName.Availability));
    } else {
      var hasCartItemsForYou = clients.some(function (client) {
        return client.id === you.id && client.cartItems.length > 0;
      });
      if (hasCartItemsForYou) {
        push(getRouteUrl(getStaffSelectionRoute()));
      } else {
        push(getRouteUrl(RouteName.Availability));
      }
    }
  }
  if (tallyModel.entries.length === 0) {
    return null;
  }
  var serviceLabel = tallyModel.entries.length > 1 ? Localize('tally.services') : Localize('tally.service');
  var totalPriceLabel = tallyModel.totalPrice && tallyModel.totalPrice > 0 ? LocalizedPrice(tallyModel.totalPrice) : undefined;
  var showFromWithTotalPrice = tallyModel.entries.some(function (serviceModel) {
    return serviceModel.hasMorePrices || serviceModel.pricedFromDisclaimer;
  }) || clients.filter(function (client) {
    return client.cartItems.length > 0;
  }).length > 1;
  function addMorePeople() {
    push(getRouteUrl(RouteName.ServiceClients));
  }
  return __jsx(MainContainer, {
    testID: "newTally",
    isVisible: !isKeyboardVisible
  }, __jsx(SummaryContainer, null, __jsx(View, null, __jsx(ServiceLabel, {
    testID: "numberOfServicesSelected"
  }, "".concat(tallyModel.entries.length, " ").concat(serviceLabel)), __jsx(View, {
    style: {
      flexDirection: 'row',
      alignItems: 'baseline'
    }
  }, totalPriceLabel ? __jsx(TotalPriceBody, null, showFromWithTotalPrice ? "".concat(Localize('services.from'), " ") : '', __jsx(PriceText, {
    testID: "servicePriceText"
  }, totalPriceLabel)) : null, totalPriceLabel && numberOfClients > 1 ? __jsx(Divider, null) : null, numberOfClients > 1 ? __jsx(Body3, null, Localize('tally.for'), ' ', __jsx(PriceText, {
    testID: "guestsCountText"
  }, Localize('tally.numberOfClients', {
    numberOfClients: numberOfClients
  }))) : null)), __jsx(Pressable, {
    testID: "showHideDetailsButton",
    style: {
      flexDirection: 'row',
      marginBottom: 16
    },
    onPress: onShowHidePress
  }, __jsx(DetailsText, null, Localize('tally.details')), __jsx(Icon, {
    name: showDetails ? 'chevron-up' : 'chevron-down',
    size: 16,
    color: theme.colors.info
  }))), showDetails ? __jsx(TallyDetails, {
    showDetails: showDetails,
    services: tallyModel.entries
  }) : null, __jsx(ButtonContainer, null, !hideGroupBooking && __jsx(AddMorePeopleButton, {
    testID: "addMorePeopleButton",
    onPress: isOnlyRenderingReward ? undefined : addMorePeople,
    disabled: isOnlyRenderingReward
  }, __jsx(AddMorePeopleButtonText, null, Localize('cart.addMorePeople'))), __jsx(MainButton, {
    onPress: onBookNow,
    type: MainButtonType.tally,
    testID: "bookButton",
    disable: isOnlyRenderingReward && !onBookWhenRenderingOnlyRewards
  }, tallyModel.canBeBooked || isOnlyRenderingReward ? Localize('tally.book') : Localize('tally.next'))));
}
var MainContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n  border-top-left-radius: 10px;\n  border-top-right-radius: 10px;\n  border-bottom-width: 0px\n  border-width: 1px\n  elevation: 2\n  shadow-color: gray\n  shadow-offset: -2px -2px\n  shadow-opacity: 0.5\n  shadow-radius: 5px\n  padding: 24px 0px\n"])), function (props) {
  return "display: ".concat(props.isVisible ? 'flex' : 'none');
}, function (props) {
  return "border-color: ".concat(Theme(props).colors.neutral5);
}, function (props) {
  return "background-color: ".concat(Theme(props).colors.footer.barColor);
}, function () {
  return isWeb() && 'position: sticky; bottom: 0';
});
var ButtonContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: 0px 16px\n"])));
var SummaryContainer = themed(Container)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  flexDirection: row\n  margin-bottom: 16px\n  align-items: center;\n  justify-content: space-between;\n  padding: 0px 16px\n"])));
var AddMorePeopleButton = themed(Pressable)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n  ", ";\n  justify-content: center;\n  align-items: center;\n  border-radius: ", "px;\n  border-width: 1px\n  border-color: ", "\n  margin-bottom: 8px;\n  margin-top: 8px;\n  height: 50px\n"])), function (props) {
  return "background-color: ".concat(Theme(props).colors.neutral);
}, function (props) {
  return props.disabled === true && "opacity: 0.5";
}, function (props) {
  return Theme(props).buttons.radius;
}, function (props) {
  return Theme(props).colors.neutral2;
});
var AddMorePeopleButtonText = themed(SubTitle2)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral7);
});
var ServiceLabel = themed(Title1)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral7);
});
var Divider = themed(Container)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  border-left-width: 1px\n  border-color: ", "\n  padding: 8px 0px 8px\n  margin-right: 8px\n  margin-left: 8px\n"])), function (props) {
  return Theme(props).colors.info;
});
var PriceText = themed(Title1)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  ", "\n  font-size: 16px;\n  line-height: 16px;\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral7);
});
var DetailsText = themed(SubTitle2)(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.info);
});
var TotalPriceBody = themed(Body3)(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  padding: 1px 0px\n"])));