import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
import React from "react";
var __jsx = React.createElement;
import { Pressable } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import { ServiceType } from '~/api/models/BookingTypes';
import { CartFunctions } from '~/api/models/CartFunctions';
import { ModelFunctions } from '~/api/models/ModelFunctions';
import Localize, { LocalizedPrice } from '~/common/Localize';
import { AnalyticsEvents } from '~/common/analytics/Analytics';
import useAnalytics from '~/common/hooks/analytics/useAnalytics';
import { removeCartItem, selectCartItems } from '~/common/slices/cart.slice';
import { selectClients } from '~/common/slices/client.slice';
import { FeatureFlagsEnum, selectFeatureFlag } from '~/common/slices/model.slice';
import { Alert } from '~/components/Alert';
import { getRouteUrl, RouteName } from '~/navigation/Routes';
import { Body2, Body3, Container, SubTitle1, SubTitle2, Theme, themed, useAppTheme } from '~/styles/Theme';
export default function ServiceCard(_ref) {
  var _category$name;
  var service = _ref.service,
    _ref$editable = _ref.editable,
    editable = _ref$editable === void 0 ? true : _ref$editable;
  var theme = useAppTheme();
  var dispatch = useDispatch();
  var analytics = useAnalytics();
  var cartItems = useSelector(selectCartItems);
  var clients = useSelector(selectClients);
  var serviceDurationFeatureFlag = useSelector(selectFeatureFlag(FeatureFlagsEnum.ServiceDuration));
  var cartItem = CartFunctions.findCartItemForService(cartItems, service);
  var hideGroupBooking = useSelector(selectFeatureFlag(FeatureFlagsEnum.HideGroupBooking));
  if (!cartItem) {
    return null;
  }
  var activeClients = clients.filter(function (client) {
    return client.cartItems.length > 0 && cartItem && client.cartItems.includes(cartItem.id);
  });
  function onCancelPress() {
    if (!cartItem) return;
    return Alert.alert(Localize('cart.removeItem.title'), Localize('cart.removeItem.message'), [{
      text: Localize('global.cancel'),
      style: 'cancel'
    }, {
      text: Localize('cart.removeItem.yes'),
      onPress: function onPress() {
        analytics.logAnalyticsEvent(AnalyticsEvents.TallyItemRemoved);
        dispatch(removeCartItem(cartItem));
      },
      style: 'destructive'
    }]);
  }
  var id = service.id,
    name = service.name,
    duration = service.duration,
    category = service.category,
    fromPrice = service.fromPrice,
    totalPrice = service.totalPrice,
    hasMorePrices = service.hasMorePrices,
    pricedFromDisclaimer = service.pricedFromDisclaimer;
  function ServiceDuration() {
    return serviceDurationFeatureFlag && duration > 0 ? __jsx(Body3, null, "".concat(Localize('services.item.approx.time'), " ").concat(duration).concat(Localize('services.item.approx.mins'))) : null;
  }
  return __jsx(ServiceCardContainer, {
    testID: "service-".concat(id)
  }, __jsx(ContentContainer, null, __jsx(Details, null, __jsx(SubTitle1, null, name), __jsx(ServiceDuration, null), category ? __jsx(Body3, null, (_category$name = category.name) !== null && _category$name !== void 0 ? _category$name : '') : null), __jsx(Price, null, (hasMorePrices || pricedFromDisclaimer || activeClients.length > 1) && __jsx(FromText, null, Localize('services.from'), " "), __jsx(SubTitle1, null, totalPrice != null ? LocalizedPrice(totalPrice) : fromPrice), service ? __jsx(Pressable, {
    style: {
      marginLeft: 8
    },
    testID: "remove-cart-item-".concat(id),
    onPress: onCancelPress
  }, __jsx(Icon, {
    name: "minus-circle",
    color: theme.colors.warning,
    size: 16
  })) : null)), clients && clients.length > 0 && !hideGroupBooking ? __jsx(ServiceFooter, {
    cartItem: cartItem,
    clients: clients,
    editable: editable && service.type !== ServiceType.Course
  }) : null);
}
function ServiceFooter(_ref2) {
  var cartItem = _ref2.cartItem,
    clients = _ref2.clients,
    editable = _ref2.editable;
  var theme = useAppTheme();
  var _useRouter = useRouter(),
    push = _useRouter.push;
  return __jsx(FooterContainer, {
    testID: "serviceCardFooter-".concat(cartItem.item.id)
  }, __jsx(FooterInfoContainer, null, __jsx(Body3, null, Localize('services.item.for'), ' ', __jsx(SubTitle2, null, clients.filter(function (client) {
    return client.cartItems.includes(cartItem.id);
  }).map(function (client) {
    return ModelFunctions.clientName(client);
  }).join(', ')))), editable ? __jsx(FooterButton, {
    onPress: function onPress() {
      return push(getRouteUrl(RouteName.ServiceClientSelection, {
        cartItemId: cartItem.id
      }));
    },
    testID: "edit-service-clients-".concat(cartItem.item.id)
  }, __jsx(Body2, {
    style: {
      color: theme.colors.info,
      marginRight: 4
    }
  }, Localize('services.item.addPeople')), __jsx(Icon, {
    name: "edit",
    color: theme.colors.info,
    size: 16
  })) : null);
}
var ServiceCardContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-radius: 4px;\n  border-width: 1px\n  ", "\n  ", "\n"])), function (props) {
  return "border-color: ".concat(Theme(props).colors.neutral4);
}, function (props) {
  return "background-color: ".concat(Theme(props).colors.neutral);
});
var ContentContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-direction: row\n  justify-content: space-between\n  align-items: flex-start\n  padding: 12px\n  ", "\n"])), function (props) {
  return "border-color: ".concat(Theme(props).colors.neutral4);
});
var Details = themed(Container)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  align-items: flex-start;\n  max-width: 55%\n"])));
var Price = themed(Container)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  flex-direction: row\n  align-items: center;\n  max-width: 45%\n"])));
var FromText = themed(Body3)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.footer.textColor);
});
var FooterContainer = themed(Container)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  padding: 12px\n  flex-direction: row\n  border-top-width: 1px\n  ", "\n"])), function (props) {
  return "border-top-color: ".concat(Theme(props).colors.neutral4);
});
var FooterInfoContainer = themed(Container)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  flex-direction: row\n  align-items: center;\n  flex: 1\n  margin-right: 16px\n"])));
var FooterButton = themed(Pressable)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  flex-direction: row\n  align-items: center;\n"])));