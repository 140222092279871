import { useEffect, useState } from 'react';
import { Keyboard, Platform } from 'react-native';
var useKeyboardAware = function useKeyboardAware() {
  var _useState = useState(false),
    isKeyboardVisible = _useState[0],
    setKeyboardVisible = _useState[1];
  useEffect(function () {
    var showListener = Keyboard.addListener(Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow', function () {
      return setKeyboardVisible(true);
    });
    var hideListener = Keyboard.addListener(Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide', function () {
      return setKeyboardVisible(false);
    });
    return function () {
      showListener.remove();
      hideListener.remove();
    };
  }, []);
  return isKeyboardVisible;
};
export default useKeyboardAware;