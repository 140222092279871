import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
import React from "react";
var __jsx = React.createElement;
import { Dimensions, ScrollView, View } from 'react-native';
import { isWeb } from '~/common/Env';
import Localize from '~/common/Localize';
import useFeedbackButton, { FeedbackButtonCtx } from '~/common/hooks/useFeedbackButton';
import RewardServiceCard from '~/components/service/RewardServiceCard';
import ServiceCard from '~/components/service/ServiceCard';
import { Container, SubTitle1, Theme, themed } from '~/styles/Theme';
export var DEFAULT_TALLY_HEIGHT = 130;
var NewTallyDetails = function NewTallyDetails(_ref) {
  var showDetails = _ref.showDetails,
    services = _ref.services;
  useFeedbackButton(showDetails ? FeedbackButtonCtx.Hidden : FeedbackButtonCtx.Tally);
  return __jsx(Container, null, __jsx(Divider, null), __jsx(DetailsContainer, {
    numOfEntries: services.length
  }, __jsx(DetailsTitle, null, Localize('categories.services')), services.map(function (service, index) {
    return __jsx(View, {
      key: index,
      style: {
        marginBottom: 16
      }
    }, service.isReward ? __jsx(RewardServiceCard, {
      service: service
    }) : __jsx(ServiceCard, {
      service: service
    }));
  })));
};
export default NewTallyDetails;
var Divider = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 3px\n  background-color: ", "\n"])), function (props) {
  return Theme(props).colors.neutral2;
});
var DetailsContainer = themed(ScrollView)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  padding: 0px 16px\n"])), function (props) {
  return !isWeb() && props.numOfEntries > 1 && "height: ".concat(Dimensions.get('window').height / 3, "px");
});
var DetailsTitle = themed(SubTitle1)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  margin: 16px 0px;\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral7);
});