import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
import React from "react";
var __jsx = React.createElement;
import { Pressable } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useDispatch, useSelector } from 'react-redux';
import { CartFunctions } from '~/api/models/CartFunctions';
import { ModelFunctions } from '~/api/models/ModelFunctions';
import Localize from '~/common/Localize';
import { AnalyticsEvents } from '~/common/analytics/Analytics';
import useAnalytics from '~/common/hooks/analytics/useAnalytics';
import { removeCartItem, selectCartItems } from '~/common/slices/cart.slice';
import { selectClients } from '~/common/slices/client.slice';
import { FeatureFlagsEnum, selectFeatureFlag } from '~/common/slices/model.slice';
import { Alert } from '~/components/Alert';
import { Body3, Container, SubTitle1, SubTitle2, Theme, themed, useAppTheme } from '~/styles/Theme';
export default function RewardServiceCard(_ref) {
  var _category$name;
  var service = _ref.service;
  var theme = useAppTheme();
  var dispatch = useDispatch();
  var analytics = useAnalytics();
  var cartItems = useSelector(selectCartItems);
  var clients = useSelector(selectClients);
  var serviceDurationFeatureFlag = useSelector(selectFeatureFlag(FeatureFlagsEnum.ServiceDuration));
  var cartItem = CartFunctions.findCartItemForServiceReward(cartItems, service);
  var hideGroupBooking = useSelector(selectFeatureFlag(FeatureFlagsEnum.HideGroupBooking));
  if (!cartItem) {
    return null;
  }
  function onCancelPress() {
    if (!cartItem) return;
    return Alert.alert(Localize('cart.removeItem.title'), Localize('cart.removeItem.message'), [{
      text: Localize('global.cancel'),
      style: 'cancel'
    }, {
      text: Localize('cart.removeItem.yes'),
      onPress: function onPress() {
        analytics.logAnalyticsEvent(AnalyticsEvents.TallyItemRemoved);
        dispatch(removeCartItem(cartItem));
      },
      style: 'destructive'
    }]);
  }
  var id = service.id,
    name = service.name,
    duration = service.duration,
    category = service.category;
  function ServiceDuration() {
    return serviceDurationFeatureFlag && duration > 0 ? __jsx(Body3, null, "".concat(Localize('services.item.approx.time'), " ").concat(duration).concat(Localize('services.item.approx.mins'))) : null;
  }
  return __jsx(ServiceCardContainer, {
    testID: "service-".concat(id)
  }, __jsx(ContentContainer, null, __jsx(Details, null, __jsx(SubTitle1, null, name), __jsx(ServiceDuration, null), category ? __jsx(Body3, null, (_category$name = category.name) !== null && _category$name !== void 0 ? _category$name : '') : null), __jsx(RemoveContainer, null, service ? __jsx(Pressable, {
    style: {
      marginLeft: 8
    },
    testID: "remove-cart-item-".concat(id),
    onPress: onCancelPress
  }, __jsx(Icon, {
    name: "minus-circle",
    color: theme.colors.warning,
    size: 16
  })) : null)), __jsx(ServiceFooter, {
    cartItem: cartItem,
    clients: clients,
    showClients: !hideGroupBooking
  }));
}
function ServiceFooter(_ref2) {
  var cartItem = _ref2.cartItem,
    clients = _ref2.clients,
    showClients = _ref2.showClients;
  var theme = useAppTheme();
  return __jsx(FooterContainer, {
    testID: "serviceCardFooter-".concat(cartItem.item.id)
  }, __jsx(FooterInfoContainer, null, showClients && __jsx(Body3, null, Localize('services.item.for'), ' ', __jsx(SubTitle2, null, clients.filter(function (client) {
    return client.cartItems.includes(cartItem.id);
  }).map(function (client) {
    return ModelFunctions.clientName(client);
  }).join(', ')))), __jsx(RewardContainer, {
    testID: 'rewardBadge'
  }, __jsx(Icon, {
    name: "gift",
    color: theme.colors.main,
    size: 16
  }), __jsx(SubTitle2, {
    style: {
      color: theme.colors.main,
      marginRight: 4
    }
  }, Localize('services.item.reward'))));
}
var ServiceCardContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-radius: 4px;\n  border-width: 1px;\n  ", "\n  ", "\n"])), function (props) {
  return "border-color: ".concat(Theme(props).colors.neutral4, ";");
}, function (props) {
  return "background-color: ".concat(Theme(props).colors.neutral, ";");
});
var ContentContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: flex-start;\n  padding: 12px;\n  ", "\n"])), function (props) {
  return "border-color: ".concat(Theme(props).colors.neutral4);
});
var Details = themed(Container)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  align-items: flex-start;\n  max-width: 55%;\n"])));
var RemoveContainer = themed(Container)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  flex-direction: row;\n  align-items: center;\n  max-width: 45%;\n"])));
var FooterContainer = themed(Container)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  padding: 12px;\n  flex-direction: row;\n  border-top-width: 1px;\n  ", "\n"])), function (props) {
  return "border-top-color: ".concat(Theme(props).colors.neutral4);
});
var FooterInfoContainer = themed(Container)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  flex-direction: row;\n  align-items: center;\n  flex: 1;\n  margin-right: 16px;\n"])));
var RewardContainer = themed(Container)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  gap: 7px;\n  padding: 8px 20px;\n  flex-direction: row;\n  border-radius: 20px;\n  ", ";\n"])), function (props) {
  return "background-color: ".concat(Theme(props).colors.main, "20");
});